export const dashboardMenu = {
    dashboard: {
        id: 'dashboard',
        text: 'Panou de control',
        path: '/',
        icon: 'fa-solid fa-qrcode',
        subMenu: null,
    },
    youtube: {
        id: 'liveMatchesYoutube',
        text: 'Foto-Video',
        path: '/youtube-live',
        icon: 'fa-solid fa-photo-film',
    }

};

export const documentsPlayer = {
    accountDocuments: {
        id: 'accountDocumentsPlayer',
        text: 'Documentele tale',
        path: '/management-account/documents-sign',
        icon: 'fa-regular fa-folder-open',
    },
}

export const appManagement = {
    management: {
        id: 'management',
        text: 'Administrare aplicatie',
        icon: 'Dashboard',
    },
    users: {
        id: 'user',
        text: 'Utilizatori',
        path: '/management/users',
        icon: 'fa-solid fa-users-gear'
    },
    transfers: {
        id: 'transfers',
        text: 'Transferuri',
        path: '/management/transfers',
        icon: 'fa-solid fa-repeat'
    },
    footballTeams: {
        id: 'footballTeams',
        text: 'Echipe',
        path: '/management/football-teams',
        icon: 'fa-brands fa-steam-symbol'
    },
    editionJournal: {
        id: 'editionJournal',
        text: 'Jurnal editii',
        path: '/management/edition-journal',
        icon: 'fa-solid fa-pen-to-square'
    },
    competitions: {
        id: 'competitions',
        text: 'Competitii',
        path: '/management/competitions',
        icon: 'fa-brands fa-playstation',
    },
    cupManagement: {
        id: 'cupManagement',
        text: 'Cupe/Baraje',
        path: '/management/cup',
        icon: 'fa-solid fa-trophy'
    },
    generalSettings: {
        id: 'generalSettings',
        text: 'Setari generale',
        path: '/management/general-settings',
        icon: 'fa-solid fa-gears',
    },
    financial: {
        id: 'financial',
        text: 'Financiar',
        path: '/management/financial',
        icon: 'fa-solid fa-coins',
    },
    docs: {
        id: 'docs',
        text: 'Documente',
        path: '/management/docs',
        icon: 'fa-regular fa-folder-open',
    }
};

export const matchManagements = {
    matchEvents: {
        id: 'matchEvents',
        text: 'Meciuri Live',
        path: '/match-management/events',
        icon: 'fa-solid fa-headset'
    },
}

export const matchStatisticsManage = {
    matchStatistics: {
        id: 'matchStatistics',
        text: 'Statistici meciuri',
        path: '/management/match-statistics',
        icon: 'fa-solid fa-chart-line',
    },
}

export const administrationAccount = {
    managementAccount: {
        id: 'managementAccount',
        text: 'Administrare cont',
        icon: 'fa-solid fa-chalkboard-user',
    },
    account: {
        id: 'account',
        text: 'Contul tau',
        path: '/management-account/account',
        icon: 'fa-solid fa-chalkboard-user'
    },

    accountDocuments: {
        id: 'accountDocuments',
        text: 'Documentele tale',
        path: '/management-account/documents-sign',
        icon: 'fa-regular fa-folder-open',
    },

    teams: {
        id: 'teams',
        text: 'Echipele tale',
        path: '/management-account/teams',
        icon: 'fa-solid fa-gear',
    },

    transfers: {
        id: 'transfers',
        text: 'Transferuri',
        path: '/management-account/transfers',
        icon: 'fa-solid fa-repeat',
    },

    players: {
        id: 'addPlayers',
        text: 'Adauga jucatori',
        path: '/management-account/add-players',
        icon: 'fa-solid fa-user-plus'
    },

    teamLot: {
        id: 'teamLot',
        text: 'Lot echipe',
        path: '/management-account/team-lot',
        icon: 'fa-brands fa-steam-symbol',
    },

    manageDisciplinary: {
        id: 'manageDisciplinary',
        text: 'Disciplinar',
        path: '/mangement-account/management-cards-suspends',
        icon: 'fa-solid fa-wand-magic-sparkles',
    },

    financial: {
        id: 'financial',
        text: 'Financiar',
        path: '/management-account/financial',
        icon: 'fa-solid fa-coins',
    }

}

export const demoPages = {
    auth: {
        id: 'auth',
        text: 'Auth Pages',
        icon: 'Extension',
    },
    login: {
        id: 'login',
        text: 'Login',
        path: 'auth-pages/login',
        icon: 'Login',
    },
    resetPassword: {
        id: 'resetPassword',
        text: 'Reset password',
        path: 'auth-pages/reset-password',
        icon: 'Login',
    },
    verifyAccount: {
        id: 'verifyAccount',
        text: 'Verify Account',
        path: 'auth-pages/verify-account',
        icon: 'Login',
    },
    register: {
        id: 'register',
        text: 'Register',
        path: 'auth-pages/register',
        icon: 'PersonAdd',
    },

    page404: {
        id: 'Page404',
        text: '404 Page',
        path: 'auth-pages/404',
        icon: 'ReportGmailerrorred',
    },
};

export const administrationSite = {
    layoutTypes: {
        id: 'administrationSite',
        text: 'Administrare Site',
    },
    media: {
        id: 'news',
        text: 'Media',
        path: 'page-layouts',
        icon: 'fa-solid fa-photo-film',
        subMenu: {
            news: {
                id: 'news',
                text: 'Stiri',
                path: 'media/news',
                icon: 'fa-regular fa-newspaper',
            },
            chronicles: {
                id: 'chronicles',
                text: 'Cronici',
                path: 'media/chronicles',
                icon: 'fa-regular fa-feather',
            },
            matches: {
                id: 'matchEvents',
                text: 'Meciuri',
                path: '/media/matches',
                icon: 'fa-solid fa-photo-film'
            },
            partners: {
                id: 'partners',
                text: 'Parteneri',
                path: '/media/partners',
                icon: 'fa-regular fa-handshake'
            },
            awards: {
                id: 'awards',
                text: 'Awards',
                path: '/media/awards',
                icon: 'fa-regular fa-award'
            },
            legends: {
                id: 'legends',
                text: 'Legende',
                path: '/media/legends',
                icon: 'fa-solid fa-person-arrow-up-from-line',
            }
        },
    },
    questions: {
        id: 'questions',
        text: 'Q&A',
        path: '/questions',
        icon: 'fa-regular fa-message-question',
    },
    menus: {
        id: 'menusLandingPage',
        text: 'Setari meniu',
        path: '/landing-page-menus',
        icon: 'fa-solid fa-gears',
    },
};
